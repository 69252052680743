import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/LayoutENG'
import BlogRoll from '../components/BlogRoll'

import ImageTloMoje from '../../static/img/banerENG.webp'
import poradnikThumb from '../../static/img/poradnikThumb.webp'
import firmaThumb from '../../static/img/firmaThumb.webp'
import stopien_zly from "../../static/img/stopien_zly.jpg";
import tuv from "../../static/img/tuv.webp";
import tlo3 from "../../static/img/tlo3.webp";
import tlo3theme from "../../static/img/tlo3theme.png";
import phone from "../../static/img/phone-big.png";
import location from "../../static/img/localisation.png";
import mail from "../../static/img/mail-big.png";

import katalog from "../../static/pliki/catalog-en.pdf";

export const IndexPageENGTemplate = ({
         image,
         title,
         subheading,
         mainpitch
       }) => (
         <div>
           <div
             className="tloEko"


      > <a href={katalog} target="_blank"  rel="noopener noreferrer">  <img alt=" " src={ImageTloMoje} alt="Ekochron" style={{ width: "100%" }} /></a></div>
           <section className="breweries" id="breweries">
             <ul>
               <li>
                 <figure>
                   <img alt=" " src={firmaThumb} alt="Firma Ekochron"></img>
                   <figcaption>
                <h3>The company</h3>
                   </figcaption>
                 </figure>
                 <p>


Ekochron is a major manufacturer of roof hardware in Poland, and builds its presence abroad.

              The company produces high quality construction materials with a special focus on finish elements for all kinds of roofs....

                 </p>
            <a href="/about">find aout more</a>
               </li>
               <li>
                 <figure>
                   <img
                     src={poradnikThumb}
                     alt="Katalog produktów Ekochron"
                   ></img>
                   <figcaption>
                <h3>Download a catalogue</h3>
                   </figcaption>
                 </figure>
                 <p>
                   The catalogue we have developed for you contains all our products and assembly guidelines,
                   so you may get more familiar with their performances and applications, and be safer in work
                   on the roof. You are invited to learn about Ekochron Brand products.
                 </p>
            <a href={katalog}>download catalogue</a>
               </li>
               <li>
                 <figure>
                   <img alt=" " src={stopien_zly} alt="Poradnik stopnia"></img>
                   <figcaption>
                <h3>Guidelines</h3>
                   </figcaption>
                 </figure>
                 <p>
                   After some years of next to no snow, nature comes back with a vengence.
                   Houses built in early 1990s had no protection against sliding snow. Expected
                   and widely discussed global warming made investors hope they would have no use
                   for such a protection, and snow fences could be put to no other use than to build
                    a home vineyard. Now, particularly after…
                 </p>
            <a href="/poradniki/2020-01-16-stopień-kominiarski-wąski-zgodny-z-normą-pn-en-516/">read more</a>
               </li>
             </ul>
           </section>
           <div
             className="full-width-image margin-top-0 tloIMG"
             style={{
               backgroundImage: `url(${
                 !!image.childImageSharp
                   ? image.childImageSharp.fluid.src
                   : image
               })`,
               backgroundPosition: `top left`,
               backgroundAttachment: `fixed`
             }}
           >
             <div
               style={{
                 display: "flex",
                 height: "150px",
                 lineHeight: "1",
                 justifyContent: "space-around",
                 alignItems: "left",
                 flexDirection: "column"
               }}
             >
               <h1
                 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen ProdukcjaBOX"
                 style={{}}
               >
                 {title}
               </h1>
               <h3
                 className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen ProdukcjaBOX"
                 style={{

                 }}
               >
                 {subheading}
               </h3>
             </div>
           </div>

           <section className="section section--gradient">
             <div className="container">
               <div className="section">
                 <div className="columns">
                   <div className="column is-10 is-offset-1">
                     <div className="content">
                       <div className="tuvIndex"><img alt="TUV" src={tuv}></img></div>
                  <div className="content jakoscBox">
                    <div className="tile ">
                           <h1 className="title">{mainpitch.title}</h1>
                         </div>
                    <div className="tile ">
                           <h3 className="subtitle">{mainpitch.description}</h3>
                         </div>
                       </div>
                       <div className="columns">

                  </div>
                   {/*
                       <Features gridItems={intro.blurbs} />
                    */}

                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
      <div className="full-width-image margin-top-0 tloIMG tloIMG2"
        style={{
          backgroundImage: `url(${tlo3})`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`
        }}>
        <div  className="tlo3theme" style={{
          backgroundImage: `url(${tlo3theme})`

        }}>
          <div className="lewy_tlo3"><h1 className="lewyH1">

            <p className="paragraf1"> <img alt="lokacja" className="imgParagraf" src={location}></img> Ekochron </p>
            <p> 43-426 Dębowiec</p>
            <p> Ogrodzona, ul. Przemysłowa 15</p>
            <p> Upper Silesia, Poland</p>
            <p> GPS coordinates:</p>
            <p> N 49.77150 E 18.72535</p><br></br>
            <p><img alt="telefon" className="imgParagraf2" src={phone}></img> phone (033) 857 90 10</p>
            <p></p><br></br>
            <a className="mailTlo3" href="mailto:sprzedaz@ekochron.pl"><img alt="mail" className="imgParagraf2"  src={mail}></img> sprzedaz@ekochron.pl </a>

          </h1></div>
          <div className="prawy_tlo3"><iframe title="Mapa Ekochron"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d41230.723231267846!2d18.721681!3d49.768758000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5674b1f88270af3d!2sEkochron!5e0!3m2!1sen!2spl!4v1578897906212!5m2!1sen!2spl" className="mapaEkochron" frameBorder="0" allowFullScreen=""></iframe></div>
         </div>
        {/*  <h3 className="has-text-weight-semibold is-size-2">
                             {heading}
                           </h3>
                           <p>{description}</p> */}
      </div>
      <div className="columns">
        <div className="indexNowosci">
        <div className="column is-12 has-text-centered ">

        </div>
      </div>
      <div className="column is-12 wpisyIndex">
        <h3 className="has-text-weight-semibold is-size-2 ">
         The last notes:
                         </h3>
        <BlogRoll />
        <div className="column is-12 has-text-centered ">
          <Link className="btn" to="/blog/indexENG">
           Read more
                           </Link>
        </div>
        </div></div>
         </div>
       );

IndexPageENGTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPageENG = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageENGTemplate
        image={frontmatter.image}
        image2={frontmatter.image2}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPageENG.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPageENG

export const pageQuery = graphql`
  query IndexPageENGTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-pageENG" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
